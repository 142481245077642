"use client";
import React, { useRef, useState } from "react";
import ProductCarousel from "./product-carousel";
import LottieIcon from "~/components/reusables/lottie-icon";
import Products_Black from "data/animation-data/Products_Black.json";
import { Button } from "~/components/ui/button";
import { ArrowLongLeft, ArrowLongRight } from "~/components/icons";

const CarouselAndControls = ({ products, product }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef<any | null>(null);

  const goNext = () => {
    swiperRef.current?.swiper?.slideNext();
  };
  const goPrev = () => {
    swiperRef.current?.swiper?.slidePrev();
  };
  const handleSlideChange = (activeIndex: number) => {
    setActiveIndex(activeIndex);
  };
  const [{ attributes }] = product;

  return (
    <>
      <div className="mt-10 h-full border md:w-[450px] lg:w-[600px] xl:w-[700px]">
        <ProductCarousel
          products={products}
          swiperRef={swiperRef}
          onSlideChange={handleSlideChange}
        />
      </div>
      <div className="z-2 flex flex-[.9] md:container md:pl-0 md:pr-0 md:pt-10">
        <div className="flex-1  px-0 pt-[50px] md:px-[60px]">
          <div className="relative mb-[20px] mt-[60px] w-[60px] md:mt-2">
            <LottieIcon
              animationData={Products_Black}
              width={80}
              height={80}
              start={10}
              end={70}
            />
          </div>
          <h2 className="font-h1 mt-[20px] py-1">{attributes.header}</h2>
          <p className="font-h5 absolute bottom-6 right-[90px] md:relative md:right-0 md:top-4 md:mb-7">
            0{activeIndex + 1} -{" "}
            <span className="text-slate-500">0{products.length}</span>
          </p>

          <h5 className="font-h5 mt-3 md:mt-6 md:pt-3">
            {attributes.subheader}
          </h5>
          <div className="absolute bottom-5 z-[2] ml-[-10px] mt-[100px] flex md:relative">
            <Button
              onClick={goPrev}
              variant="ghost"
              aria-label="Prev"
              disabled={activeIndex === 0}
              className="p-0"
            >
              <ArrowLongLeft size={55} />
            </Button>
            <Button
              onClick={goNext}
              variant="ghost"
              aria-label="Next"
              disabled={activeIndex === products.length - 1}
              className="p-0"
            >
              <ArrowLongRight size={55} />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselAndControls;
