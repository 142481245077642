import React from "react";
import Image from "next/image";
import Link from "next/link";

const ProductCarouselCard = ({ product }) => {
  const productLogo = product.attributes.productLogo.data[0].attributes;
  return (
    <Link href={product.attributes.ui[0].url} target="_blank">
      <div className="relative h-[250px] overflow-hidden">
        <Image
          unoptimized
          src={product.attributes?.productThumbnail.data.attributes.url}
          alt="Product Thumbnail"
          className="w-full object-cover transition-all duration-700 group-hover:scale-105"
          fill
          sizes="100%"
        />
      </div>
      <div className="container py-10">
        <div className="flex items-center justify-between gap-4">
          <div className="relative">
            <Image
              src={productLogo.url}
              alt="Product Logo"
              width="0"
              height="0"
              sizes="100vw"
              unoptimized
              className="h-auto w-full lg:max-w-[256px]"
            />
          </div>
          <div className="relative lg:hidden">
            <Image
              src="/images/graphics/link-graphic-white.png"
              alt="learn more"
              width={39}
              height={33}
              unoptimized
              className="h-auto w-full"
            />
          </div>
          <div className="hidden items-center justify-around gap-1 lg:flex">
            <h6 className="w-[100px] text-[14px] text-white group-hover:underline">
              LEARN MORE
            </h6>
            <div>
              <Image
                src="/images/graphics/link-graphic-white.png"
                alt="learn more"
                width={39}
                height={33}
                unoptimized
                className="h-auto w-full"
              />
            </div>
          </div>
        </div>
        <div className="mt-10 text-white">
          <p className="font-h4">{product.attributes?.productSubheader}</p>
          <p className="font-p mb-5 mt-5">
            {product.attributes?.productDescription}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default ProductCarouselCard;
