"use client";
import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import ProductCarouselCard from "./product-carousel-card";

type SwiperRef = {
  swiper: SwiperType;
};
type Props = {
  products: any[];
  swiperRef: React.MutableRefObject<SwiperRef | null>;
  onSlideChange?: (activeIndex: number) => void;
};
const ProductCarousel = ({ products, swiperRef, onSlideChange }: Props) => {
  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;

      const handleSlideChange = () => {
        onSlideChange?.(swiper.activeIndex);
      };

      swiper.on("slideChange", handleSlideChange);

      return () => {
        swiper.off("slideChange", handleSlideChange);
      };
    }
  }, [swiperRef, onSlideChange]);
  return (
    <Swiper
      className="mySwiper max-w-full bg-black"
      slidesPerView={1}
      // @ts-ignore
      ref={swiperRef}
    >
      {products.map((product, index) => {

return (
          <SwiperSlide
            key={`${product.id}-${index}`}
            className="group bg-black"
          >
            <ProductCarouselCard product={product} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default ProductCarousel;
